import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  OutlinedInput,
  styled,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import SearchIcon from '@mui/icons-material/Search'
import RoomIcon from '@mui/icons-material/Room'
import ScheduleIcon from '@mui/icons-material/Schedule'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import Spinner from '../loading/spinner'
import { useCart } from '../../hooks/useCart'
import { CartType } from '../../services/api/endpoints/carts'
import {
  QueryParams,
  useLazyGetTeamsQuery,
} from '../../services/api/endpoints/teams'

const StyledSearchResultText = styled('span')`
  color: #3c3835;
`

const StyledSearchResultItem = styled('div')`
  margin: 15px 0;
`

const StyledSearchResultHeader = styled('div')`
  margin-bottom: 25px;
`

const StyledSearchResultDetails = styled('div')`
  margin-bottom: 40px;
`

const StyledSearchResultFooter = styled('div')`
  color: #3c3835;
`

const ClearFix = styled('div')`
  clear: both;
`

const StyledImageContainer = styled('div')`
  float: right;
`

const StyledImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        height: '40px',
      },
    }
  }}
  height:50px;
`

const StyledSearchResultHeaderContent = styled('div')`
  float: left;
  color: #3c3835;
`

const StyledFormControl = styled(FormControl)`
  background-color: #fff;
  border-color: white !important;
`

const StyledFormSearchContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 40px;
`

const StyledSearchResult = styled(Box)`
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 18px;
  box-shadow: 0px 5px 10px 0px #eee;
`

const StyledSearchResultHeaderTitle = styled('div')`
  font-weight: bold;
  font-size: 20px;
`

const StyledSearchResultHeaderSubtitle = styled('div')`
  font-size: 18px;
  margin-top: 5px;
`

const StyledContainer = styled(Container)`
  margin-top: 60px;
`

const searchResultIconStyles = {
  fill: '#008174',
  marginBottom: '-5px',
  marginRight: '10px',
}

interface PlayerTeamListProps {
  q?: string
}

const PlayerTeamList = ({ q }: PlayerTeamListProps) => {
  const cartType: CartType = 'player'

  const { setAssociation, setCompetition, setTeam } = useCart({
    type: cartType,
  })

  const [params] = useState<QueryParams>({
    currentSeason: true,
    isOpen: true,
    limit: 100000,
    offset: 0,
    openFor: 'player',
    search: '',
  })
  const [filteredTeams, setFilteredTeams] = useState([])
  const [trigger, result] = useLazyGetTeamsQuery()
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const { data: teams } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  useEffect(() => {
    if (q) {
      setSearch(q)
    }
  }, [q])

  useEffect(() => {
    if (search === '') {
      setFilteredTeams([])
    }
    if (teams) {
      if (search !== '') {
        setFilteredTeams(filterByValue(teams, search))
      }
      setIsLoading(false)
    }
  }, [search, teams])

  const filterByValue = (array: any, string: string) => {
    return array.filter((o: any) =>
      o.team.name.toLowerCase().includes(string.toLowerCase()),
    )
  }

  const handleClick = async (
    associationId: string,
    competitionId: string,
    teamId: string,
  ) => {
    await setAssociation({ associationId, type: cartType }).unwrap()
    await setCompetition({ competitionId, type: cartType }).unwrap()
    await setTeam({ teamId, type: cartType }).unwrap()

    navigate(`/register/player/profile`)
  }

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        <StyledContainer>
          <StyledFormSearchContainer>
            <StyledFormControl fullWidth variant="outlined">
              <OutlinedInput
                id="outlined-search"
                type="text"
                placeholder="Enter the name of the team you wish to register to"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </StyledFormControl>
          </StyledFormSearchContainer>

          {filteredTeams && !!filteredTeams.length!! ? (
            filteredTeams.map((team: any) => (
              <SearchResult
                key={team?.team?._id}
                handleClick={handleClick}
                team={team}
              />
            ))
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <p>NO RESULTS FOUND</p>
              <p>
                Please check your search for any spelling errors or try a
                different search term
              </p>
            </Box>
          )}
        </StyledContainer>
      )}
    </>
  )
}

const SearchResult = ({ team, handleClick }: any) => {
  const competitionTimesParts = team?.competition?.time?.split(' - ')
  const startTime = competitionTimesParts[0]
    ? moment(competitionTimesParts[0], 'hh:mmA').format('h:mma')
    : ''
  const endTime = competitionTimesParts[1]
    ? moment(competitionTimesParts[1], 'hh:mmA').format('h:mma')
    : ''

  return (
    <StyledSearchResult>
      <Box
        onClick={() =>
          handleClick(team.association._id, team.competition._id, team.team._id)
        }
      >
        <StyledSearchResultHeader>
          <StyledSearchResultHeaderContent>
            <StyledSearchResultHeaderTitle>
              {team.team.name}
            </StyledSearchResultHeaderTitle>
            <StyledSearchResultHeaderSubtitle>
              {team.competition.name}
            </StyledSearchResultHeaderSubtitle>
            <StyledSearchResultHeaderSubtitle>
              {team.association.name}
            </StyledSearchResultHeaderSubtitle>
          </StyledSearchResultHeaderContent>
          {team.association.logo && (
            <StyledImageContainer>
              <StyledImg src={team.association.logo} alt="" />
            </StyledImageContainer>
          )}
          <ClearFix />
        </StyledSearchResultHeader>
        <StyledSearchResultDetails>
          <StyledSearchResultItem>
            <RoomIcon style={searchResultIconStyles} />
            <StyledSearchResultText>
              {team.competition.venue.address.formatted}
            </StyledSearchResultText>
          </StyledSearchResultItem>
          <StyledSearchResultItem>
            <ScheduleIcon style={searchResultIconStyles} />
            <StyledSearchResultText>
              {`${team?.competition?.dayPlayed} ${startTime} - ${endTime}`}
            </StyledSearchResultText>
          </StyledSearchResultItem>
          {team?.competition?.registrationSettings.player.startDate &&
            moment(
              team?.competition?.registrationSettings.player.startDate,
            ).isValid() && (
              <StyledSearchResultItem>
                <CalendarTodayIcon style={searchResultIconStyles} />
                <StyledSearchResultText>
                  {moment(
                    team?.competition?.registrationSettings.player.startDate,
                  ).format('Do MMM YYYY')}
                </StyledSearchResultText>
              </StyledSearchResultItem>
            )}
        </StyledSearchResultDetails>
        <StyledSearchResultFooter>
          Registration close{' '}
          <strong>
            {team?.competition?.registrationSettings?.player?.endDate
              ? moment(
                  team.competition.registrationSettings.player.endDate,
                ).format('Do MMM YYYY')
              : 'TBC'}
          </strong>
        </StyledSearchResultFooter>
      </Box>
    </StyledSearchResult>
  )
}

PlayerTeamList.defaultProps = {
  q: '',
}

export default PlayerTeamList
